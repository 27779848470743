import React from "react";
import { Container } from "reactstrap";

export default () => {
    return (
        <footer id="main-footer">
			<Container className="muted small text-center">
				{new Date().getFullYear()} © AM Consortium Investment AB. All Rights Reserved.
			</Container>
        </footer>
    );
}

import React from "react";
import { Container } from "reactstrap";
import Logotype from "./logotype";

export default () => {
    return (
        <header id="main-header">
			<Container>
				<Logotype />
			</Container>
        </header>
    );
}

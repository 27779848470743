import React from "react";
import PropTypes from "prop-types";
import Header from "../components/header";
import Footer from "../components/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style/compiled.scss";

const Layout = ({ children }) => {

    return(
        <div id="site-container">
            <Header />
            <main id="main-content-container">
                {children}
            </main>
            <Footer />
        </div>
    );
}
Layout.propTypes = {
    children: PropTypes.node.isRequired
}
export default Layout;
import React from "react";

export default (props) => {
	return(
		<svg id="logotype" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 435 187.7">
		<path fill="none" d="M956.2,643.2h-3.5L789.3,447.3v311.8h-71.4V268.4h3.5l234.8,270.8l67.8-79.2V0H0v491.2h133.1V278.3
			h75.7v480.9h-75.7V558.3H0V1024h1024V562.2L956.2,643.2z M629.5,759.2H313.4V278.3h306.2v67.2H389.1v137.9h204.4v65.8H389.1V692
			h240.4V759.2z"/>
			<g>
				<g>
					<path className="strokePath" id="lt0" d="M54.6,56.7c5,0,8.7,0.3,12.3,0.5v-2.6c0-18.1-6.3-25.7-21.3-25.7c-16.8,0-26.3,8.1-31.2,13.7L0.5,21
						C7.6,12.9,22.3,0,51.5,0c34.9,0,49.6,20,49.6,55.4v77.2H68.8V120h-0.3c-3.4,5-11,15.8-33.1,15.8C13.1,135.7,0,121.3,0,97.1
						C0,70.9,21.5,56.7,54.6,56.7z M47.5,108.4c11.8,0,17.1-5.3,19.4-8.1V78.5c-2.9,0-5.8-0.3-9.7-0.3c-15.5,0-24.2,5-24.2,15.2
						C33.1,103.2,38.9,108.4,47.5,108.4z"/>
					<path className="strokePath" id="lt1" d="M161.2,15.8h0.5c5-6,15.5-15.8,35.2-15.8c13.7,0,25.2,5.3,32.3,17.1C234.7,10.5,247,0,268,0c24.4,0,40.7,14.2,40.7,48.3
						v84.3h-35.4v-79c0-16.3-5.3-23.9-17.6-23.9c-8.9,0-15.8,5-20.2,9.5c0.3,2.9,0.5,6,0.5,9.2v84.3h-35.4v-79
						c0-16.3-5.8-23.9-17.3-23.9c-10,0-16.3,5.8-20.2,10.2v92.7h-35.2V3.1h33.3V15.8z"/>
					<path className="strokePath" id="lt2" d="M418.7,36.8c-4.7-3.4-10.2-7.1-21-7.1c-17.6,0-32.3,11.3-32.3,37c0,26.8,11.8,38.6,30.5,38.6c12.6,0,19.7-3.9,23.4-6.8
						l9.7,26.5c-6,4.2-18.1,10.8-39.4,10.8c-36.2,0-60.1-21.3-60.1-67.7c0-46.2,32-68,64.3-68c20.2,0,29.7,6,36.8,11.3L418.7,36.8z"/>
				</g>
				<rect id="logotype-line-seperator" x="0" y="155.4" width="430.5" height="2"/>
				<g>
					<path id="lb0" d="M2.2,187.4H0v-18h2.2V187.4z"/>
					<path id="lb1" d="M51.5,187.4h-1.9l-9-13.7h0v13.7h-2.2v-18h1.9l9,13.7h0v-13.7h2.2V187.4z"/>
					<path id="lb2" d="M94.3,187.4h-2.2l-6.2-18h2.3l5,15.2h0.1l5-15.2h2.2L94.3,187.4z"/>
					<path id="lb3" d="M145.2,171.4h-8.1v5.8h7.3v2h-7.3v6.3h8.3v2H135v-18h10.2V171.4z"/>
					<path id="lb4" d="M188.8,172.6c-0.6-0.4-1.9-1.4-4-1.4c-1.9,0-3.4,0.8-3.4,2.5c0,2,1.5,2.5,4.4,3.7c2.9,1.1,4.5,2.5,4.5,5.4
						c0,3-2.1,5.1-5.8,5.1c-3.1,0-4.6-1.1-5.5-1.8l0.9-2c0.6,0.5,2,1.8,4.6,1.8c2.1,0,3.6-0.9,3.6-2.8c0-1.9-1.3-2.8-4-3.9
						c-2.7-1.1-4.9-2.3-4.9-5.3c0-2.8,2.2-4.6,5.6-4.6c2.7,0,4.1,1,4.8,1.6L188.8,172.6z"/>
					<path id="lb5" d="M237.2,171.4h-5.9v16h-2.2v-16h-6v-2h14.1V171.4z"/>
					<path id="lb6" d="M288.1,187.4h-2.2v-13.3h-0.1l-5.2,13.3h-1.9l-5.3-13.3h-0.1v13.3h-2.2v-18h2.5l5.9,14.7h0.1l5.9-14.7h2.4V187.4z"/>
					<path id="lb7" d="M334.2,171.4h-8.1v5.8h7.3v2h-7.3v6.3h8.3v2H324v-18h10.2V171.4z"/>
					<path id="lb8" d="M382.4,187.4h-1.9l-9-13.7h0v13.7h-2.2v-18h1.9l9,13.7h0v-13.7h2.2V187.4z"/>
					<path id="lb9" d="M430.5,171.4h-5.9v16h-2.2v-16h-6v-2h14.1V171.4z"/>
				</g>
			</g>
		</svg>
	)
}